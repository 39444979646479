import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Emissivity = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { emissivity } = useTranslations();

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{emissivity}</h2>
					<div className="content-container doc">
						<div className="flex column">
							<div className="header medium lh">
								測定物の放射率は同一名称であっても、その組成、表面処理、きめのこまかさ、色そして測定時の温度などで影響を受けます。
								そのため、ある物体の放射率を正確に表わすことは非常に困難です。特に金属は表面の加工で放射率は大きく変わります。
								<br />
								下表はあくまでも目安としてお考えください。<br />
								（放射率表のpdfファイルは
								<a  href="https://static.sksato.co.jp/custom_document/ja/cd_emissivity.pdf" target="_blank" rel="noreferrer">
									「こちら」
								</a>
								をクリックしてください）
								
							</div>
							<div className="content">
								<table className="table">
									<thead>
										<tr>
											<th className="th medium">物体</th>
											<th className="th medium">放射率（ε）</th>
										</tr>
									</thead>
									<tbody>
　　									<tr>
											<td className="medium td">鉄</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">鋳鉄</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">ニッケル</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">アルミ</td>
											<td className="medium td">0.30</td>
										</tr>
										<tr>
											<td className="medium td">銅</td>
											<td className="medium td">0.80</td>
										</tr>
										<tr>
											<td className="medium td">真鍮</td>
											<td className="medium td">0.60</td>
										</tr>
										<tr>
											<td className="medium td">ニクロム</td>
											<td className="medium td">0.60</td>
										</tr>
										<tr>
											<td className="medium td">ガラス</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">セラミック</td>
											<td className="medium td">0.80</td>
										</tr>
										<tr>
											<td className="medium td">タイル</td>
											<td className="medium td">0.80</td>
										</tr>
										<tr>
											<td className="medium td">アスベスト</td>
											<td className="medium td">0.90</td>
										</tr>
										<tr>
											<td className="medium td">アスファルト</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">コンクリート</td>
											<td className="medium td">0.95</td>
										</tr>
										<tr>
											<td className="medium td">土</td>
											<td className="medium td">0.95</td>
										</tr>
										<tr>
											<td className="medium td">木材</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">紙</td>
											<td className="medium td">0.92</td>
										</tr>
										<tr>
											<td className="medium td">布</td>
											<td className="medium td">0.75</td>
										</tr>
										<tr>
											<td className="medium td">プラスチック</td>
											<td className="medium td">0.95</td>
										</tr>
										<tr>
											<td className="medium td">ゴム</td>
											<td className="medium td">0.95</td>
										</tr>
										<tr>
											<td className="medium td">カーボン</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">皮膚</td>
											<td className="medium td">0.97</td>
										</tr>
										<tr>
											<td className="medium td">水</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">海水</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">雪</td>
											<td className="medium td">0.80～0.85</td>
										</tr>
										<tr>
											<td className="medium td">氷</td>
											<td className="medium td">0.97</td>
										</tr>
										<tr>
											<td className="medium td">雲</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">肉・魚</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">野菜</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">くだもの</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">パン・菓子</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">穀類</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">油</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">塗料</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">紙類</td>
											<td className="medium td">0.92</td>
										</tr>
										<tr>
											<td className="medium td">ダンボール</td>
											<td className="medium td">0.81</td>
										</tr>
										<tr>
											<td className="medium td">パック類</td>
											<td className="medium td">0.92</td>
										</tr>
										<tr>
											<td className="medium td">フライパン</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">鉄板</td>
											<td className="medium td">0.85</td>
										</tr>
									</tbody>
								</table>
								<p className="medium">
									※一覧表のデータは主な物体の放射率の大まかな目安です。
									<br />
									※金属は酸化されたものです。
								</p>
							</div>
							<div className="header medium lh">
								上述のとおり、測定物の放射率は、同一の名称であっても表面の加工などで異なります。
								<br />
								正確に測定するには、測定物の温度を精度の高い接触式表面温度計などで測定し、実際の温度に沿うよう放射率を調整してご使用ください。
							</div>
						</div>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Emissivity;
